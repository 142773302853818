/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, ContactForm } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-ttyirh --full" name={"einleitung"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/img-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--1 pb--40 flex--bottom" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62 w--400 lh--1" style={{"maxWidth":640}} content={"<span style='color: white'>Bau- und Elektroarbeiten für Wohnungen</span>"}>
              </Title>

              <Text className="text-box fs--20 ls--02 lh--16 mt--20" style={{"maxWidth":640}} content={"<span style='color: white'>Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen.</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--shadow5" href={"#[[T75SecName7]]"} content={"Kontaktieren Sie uns"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"dienstleistungen"}>
          
          <ColumnWrap className="column__flex --center el--3 pb--20 pt--20 flex--center" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/88e67c255f7d45fbadefead760a44b9f.svg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={""} content={null}>
              </Image>

              <Title className="title-box" content={"Rekonstruktion"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/o/g/Ikonky/kvetinarstvi/1c76659180bd4159b6d9b4804db35767.svg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={""} content={null}>
              </Image>

              <Title className="title-box" content={"Externe Arbeit"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":300}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/1bf57d65067e4fcd82d8e6c77685617b.svg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={""} content={null}>
              </Image>

              <Title className="title-box w--300 ls--002" content={"Malen von Gebäuden"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogallerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/83/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/83/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/83/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/83/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/83/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/83/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/83/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/83/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/83/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/83/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/83/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/83/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/83/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/83/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/83/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/83/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/83/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/83/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/83/img-1_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/83/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/83/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/83/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/83/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/83/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"referenzen"} style={{"paddingTop":null,"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1 pb--60 pt--60" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 w--400" content={"<span style='color: var(--color-dominant)'>Referenzen</span>"}>
              </Title>

              <Text className="text-box fs--20 lh--16" style={{"maxWidth":510}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--shadow5 fs--20" href={"#[[T75SecName7]]"} content={"Kontaktieren Sie uns"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"referenzen-2"} style={{"backgroundColor":"var(--color-blend-95)"}}>
          
          <ColumnWrap className="column__flex --center el--3 mt--0 flex--top" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"var(--color-dominant)"}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/83/img-1_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/83/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/83/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/83/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/83/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/83/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box mt--06" content={"Rekonstruktion des Kunsthauses im Prag"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"var(--color-dominant)"}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/83/img-2_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/83/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/83/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/83/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/83/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/83/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box mt--06" content={"Rekonstruktion des Kunsthauses im Prag"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"var(--color-dominant)"}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/83/img-3_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/83/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/83/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/83/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/83/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/83/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box mt--06" content={"Rekonstruktion des Kunsthauses im Prag"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"var(--color-dominant)"}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/83/img-1_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/83/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/83/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/83/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/83/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/83/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box mt--06" content={"Rekonstruktion des Kunsthauses im Prag"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"var(--color-dominant)"}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/83/img-2_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/83/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/83/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/83/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/83/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/83/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box mt--06" content={"Rekonstruktion des Kunsthauses im Prag"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"var(--color-dominant)"}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/83/img-3_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/83/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/83/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/83/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/83/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/83/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box mt--06" content={"Rekonstruktion des Kunsthauses im Prag"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kontakt"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 pb--60 pt--60" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"<span style='color: var(--color-dominant)'>Bau- und Elektroarbeiten für Wohnungen</span>"}>
              </Title>

              <Text className="text-box fs--20 ls--0 lh--16" content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--shadow5" href={"#[[T75SecName7]]"} content={"Kontaktieren Sie uns"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--20 lh--16" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

              <Text className="text-box fs--20 w--300 ls--0 lh--16" content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

              <Text className="text-box fs--20 lh--16" content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1yv4g4y bg--bottom pb--80 pt--80" name={"kontakt-2"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--25 pt--25" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"<span style='color: white'>Wir werden Sie kontaktieren</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape2 --style3 fs--18" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Ganze Name","type":"text","required":true,"placeholder":"Bitte tragen Sie Ihren Namen ein"},{"name":"Telefon Nr.","type":"text","placeholder":"Telefon Nr."},{"name":"E-Mail Kontakt:","type":"email","placeholder":"Bitte geben Sie Ihre e-Mail Adresse ein"},{"name":"Kontaktieren Sie uns","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"partei"}>
          
          <ColumnWrap className="column__flex el--4 mb--60 mt--60" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Bauarbeiten"} maxWidth={"240"}>
              </Title>

              <Text className="text-box fs--16 lh--16" content={"Fangen Sie an zu schreiben. Dieser Bereich ist für Ihren Text bestimmt."} maxWidth={"240"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Kontaktieren Sie uns"} maxWidth={"180"}>
              </Title>

              <Text className="text-box fs--16 lh--16" content={"Oeder Weg 22<br>Frankfurt<br>a/M."} maxWidth={"240"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Navigation"} maxWidth={"240"}>
              </Title>

              <Text className="text-box fs--16 lh--16" content={"<a href='#[[T75SecName0]]'>Nach oben</a>"} maxWidth={"240"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--0" content={"<a href='#[[T75SecName2]]'>Dienstleistungen</a>"} maxWidth={"240"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--0" content={"<a href='#[[T75SecName5]]'>Referenzen</a>"} maxWidth={"240"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--0" content={"<a href='#[[T75SecName6]]'>Information</a>"} maxWidth={"240"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box fs--26 lh--14" content={"<span style=\"text-decoration-line: underline;\">facebook</span>"}>
              </Text>

              <Text className="text-box fs--26 lh--14 mt--0" content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}